import { useEffect, useRef, useState } from 'react'

const MAX_LOAD_ATTEMPTS = 10

export function useScriptInit({
  hasLoadedFn = () => true,
  initFn,
}: {
  hasLoadedFn?: () => boolean
  initFn: () => void
}) {
  const loadAttempts = useRef(0)
  // null | false -> not initialized yet. true -> initialized
  const [didScriptInit, setDidScriptInit] = useState<null | boolean>(null)

  // Will switch between null and false to retrigger init if needed
  function onLoad() {
    setDidScriptInit((didInit) =>
      didInit === null ? false : didInit === false ? null : didInit
    )
  }

  useEffect(() => {
    // Either null or false
    if (!didScriptInit && loadAttempts.current < MAX_LOAD_ATTEMPTS) {
      if (hasLoadedFn()) {
        initFn()
        setDidScriptInit(true)
      } else {
        loadAttempts.current++
        // In case script did not load, retry
        setTimeout(onLoad, 150)
      }
    }
  }, [didScriptInit])

  return onLoad
}
