import React, { type ReactNode } from 'react'
import {
  ThemeProvider as StyledThemeProvider,
  StyleSheetManager,
} from 'styled-components'
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material'
import SnackbarProvider from './elements/Snackbar/SnackbarProvider'
import { AppErrorFallbackWrapper } from './components/AppErrorFallback/AppErrorFallback'
import { muiTheme, theme } from 'mixtiles-web-common/elements/Theme/photosTheme'
import { type Theme } from '@material-ui/core'
import {
  TilesSourceContext,
  type TilesSourceContextValue,
} from 'components/TilesProvider/TilesSourceContext'
import { TILES_SOURCE } from 'components/TilesProvider/TilesProvider.consts'
import { domAnimation, LazyMotion } from 'framer-motion'

import SideMenuProvider from 'components/SideMenu/SideMenuProvider'
import { AuthProvider } from './services/AuthProvider/AuthProvider'
import { ApiProvider } from './api/apiProvider'
import { DialogProvider } from './elements/Dialog/DialogProvider'
import { PricingProvider } from './components/Pricing/PricingProvider'
import { AccountProvider } from './components/Account/AccountProvider'
import EmbeddedLoginProvider from './components/EmbeddedLogin/EmbeddedLoginProvider'
import ProductTypeProvider from './services/ProductTypesManager'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const tileSourceDefaultValue: TilesSourceContextValue = {
  tilesSource: TILES_SOURCE.PHOTO_STYLER,
  updateTilesSource: () => {},
}

export default function DefaultProviders({
  children,
}: {
  children: ReactNode
}) {
  return (
    <StyleSheetManager enableVendorPrefixes>
      <TilesSourceContext.Provider value={tileSourceDefaultValue}>
        <StyledThemeProvider theme={theme}>
          <MuiThemeProvider theme={muiTheme}>
            <StyledEngineProvider injectFirst>
              <ProductTypeProvider>
                <AuthProvider>
                  <ApiProvider>
                    <DialogProvider>
                      <PricingProvider>
                        <AccountProvider>
                          <AppErrorFallbackWrapper>
                            <LazyMotion features={domAnimation} strict>
                              <EmbeddedLoginProvider>
                                <SideMenuProvider>
                                  <SnackbarProvider>
                                    {children}
                                  </SnackbarProvider>
                                </SideMenuProvider>
                              </EmbeddedLoginProvider>
                            </LazyMotion>
                          </AppErrorFallbackWrapper>
                        </AccountProvider>
                      </PricingProvider>
                    </DialogProvider>
                  </ApiProvider>
                </AuthProvider>
              </ProductTypeProvider>
            </StyledEngineProvider>
          </MuiThemeProvider>
        </StyledThemeProvider>
      </TilesSourceContext.Provider>
    </StyleSheetManager>
  )
}
