import React, { forwardRef, type PropsWithChildren } from 'react'
import { SnackbarContent, type SnackbarKey, useSnackbar } from 'notistack'
import { useSwipeable } from 'react-swipeable'
import * as S from './SwipeableSnackbar.styles'
import { analytics, EVENT_NAMES } from '../../../services/Analytics/Analytics'

const SwipeableSnackbar = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ id: SnackbarKey; name: string; text: string }>
>((props, ref) => {
  const { closeSnackbar } = useSnackbar()
  const { children, id, name, text } = props

  const handlers = useSwipeable({
    onSwipedUp: () => {
      analytics.track(EVENT_NAMES.TOAST_DISMISSED, {
        Name: name,
        Text: text,
        Method: 'Swipe',
      })
      closeSnackbar(id)
    },
  })

  return (
    <S.Container
      {...handlers}
      // required those two handlers to prevent clickAwayListener from MUI from executing its action.
      onClick={(e) => {
        e.stopPropagation()
      }}
      onTouchEnd={(e) => {
        e.stopPropagation()
      }}
    >
      <SnackbarContent ref={ref} style={{ justifyContent: 'center' }}>
        {children}
      </SnackbarContent>
    </S.Container>
  )
})

export default SwipeableSnackbar
