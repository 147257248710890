import { useLocation } from '@remix-run/react'
import useOnMount from 'hooks/useOnMount'
import { useScriptInit } from 'utils/scriptUtils'

export function KeysScript({ keys }: { keys: Record<string, any> }) {
  return (
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `KEYS = ${JSON.stringify(keys)}`,
      }}
    />
  )
}

const useGtmId = () => {
  const location = useLocation()
  return location.pathname.startsWith('/art') ? 'GTM-N2RZGPQ' : 'GTM-KPZ2SGN'
}

export function BodyScripts({ keys }: { keys: Record<string, any> }) {
  const gtmId = useGtmId()

  const onBounceLoad = useScriptInit({
    hasLoadedFn: () => !!window.bouncepay,
    initFn: () => {
      window.bouncepay.init()
    },
  })

  const onAcsbLoad = useScriptInit({
    hasLoadedFn: () => !!window.acsbJS,
    initFn: () => {
      window.acsbJS.init({
        statementLink: '',
        footerHtml: '',
        hideMobile: false,
        hideTrigger: true,
        language: 'en',
        position: 'left',
        leadColor: '#FF0072',
        triggerColor: '#FF0072',
        triggerRadius: '50%',
        triggerPositionX: 'right',
        triggerPositionY: 'bottom',
        triggerIcon: 'people',
        triggerSize: 'medium',
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
          triggerSize: 'small',
          triggerPositionX: 'right',
          triggerPositionY: 'bottom',
          triggerOffsetX: 10,
          triggerOffsetY: 10,
          triggerRadius: '50%',
        },
      })
    },
  })

  useOnMount(() => {
    window._tfa = window._tfa || []
    window._tfa.push({ notify: 'event', name: 'page_view', id: 1263317 })
  })

  return (
    <>
      <script
        type="text/javascript"
        src={`//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${keys.klaviyoToken}`}
        async
        defer
      />
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}

      {/* <!-- Taboola Pixel Code --> */}
      <script
        src="//cdn.taboola.com/libtrc/unip/1263317/tfa.js"
        defer
        async
        id="tb_tfa_script"
        type="text/javascript"
      />
      <noscript>
        <img
          src="https://trc.taboola.com/1263317/log/3/unip?en=page_view"
          width="0"
          height="0"
          style={{ display: 'none' }}
        />
      </noscript>
      {/* <!-- End of Taboola Pixel Code --> */}
      {/* <script id="__SUB_SLICK_JQUERY__"></script> */}

      {/* <!-- flipclock--> */}
      <script id="flipclock-js" src="/flipclock/flipclock.min.js" async defer />
      {/* <!-- --> */}

      <script
        src="https://acsbapp.com/apps/app/dist/js/app.js"
        async
        defer
        onLoad={onAcsbLoad}
      />

      <script src={keys.bounceScriptURL} async defer onLoad={onBounceLoad} />
    </>
  )
}

export function HeadScripts({ keys }: { keys: Record<string, any> }) {
  const gtmId = useGtmId()

  const onIreLoad = useScriptInit({
    hasLoadedFn: () => !!window.ire,
    initFn: () => {
      window.ire('identify', { customerId: '', customerEmail: '' })
    },
  })
  const onGtmLoad = useScriptInit({
    hasLoadedFn: () => !!window.dataLayer,
    initFn: () => {
      window.dataLayer.push([
        'event',
        'conversion',
        {
          send_to: 'AW-770514071',
          aw_remarketing_only: true,
        },
      ])
    },
  })

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
      window.global ||= window; window.requestTime = Date.now();
      window.UC_UI_SUPPRESS_CMP_DISPLAY = true;
      if (!window._learnq) { 
        window._learnq = [];
      }
      `.trim(),
        }}
      />

      {/* <!-- This script blocks non consented requests https://docs.usercentrics.com/#/smart-data-protector --> */}
      <script
        id="usercentrics-cmp"
        src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
        data-ruleset-id="MU39HE-m4BxMR3"
        data-avoid-prefetch-services=""
      />
      <script src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js" />

      {/* <!-- Filestack --> */}
      <script
        async
        src="//static.filestackapi.com/filestack-js/3.x.x/filestack.min.js"
      />
      {/* <!-- End Filestack --> */}

      {/* <!-- Impact Radius --> */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.ire_o = 'ire'
          window.ire = window.ire || function () {
            (window.ire.a = window.ire.a || []).push(arguments)
          }`,
        }}
      />
      <script
        src="https://utt.impactcdn.com/A2951962-c164-46b5-86aa-761bb748ae001.js"
        defer
        async
        onLoad={onIreLoad}
      />
      {/* <!-- End Impact Radius --> */}

      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.performance.mark('html_load_' + window.location.pathname)
          window.force11UsdPricing = false;`,
        }}
      />

      {/* Redirect from app.mixtiles.com to mixtiles.com */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
          if (window.location.host === 'app.mixtiles.com') {
            window.location = 'https://www.mixtiles.com' + window.location.pathname + window.location.search + (window.location.search ? '&' : '?') + 'newSite=true'
          }`,
        }}
      />

      {/* <!--
        Enable traffic origin tracking (for Analytics)
         for further reading: https://www.notion.so/mixtiles/Google-Analytics-changes-9cf7038d73094f4bb51b400e898ad7d1
         --> */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
          dataLayer = []
          dataLayer.push({
            originalLocation: document.location.protocol + '//' +
                    document.location.hostname +
                    document.location.pathname +
                    document.location.search
          });`,
        }}
      />
      {/* <!-- End of Analytics  --> */}

      {/* GTM */}
      {keys.isGoogleTagActive.toString() === 'true' && (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `
            (function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({
                'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
              });
            })(window, document, 'script', 'dataLayer', '${gtmId}');
          `,
            }}
          />
          <script
            src={`https://www.googletagmanager.com/gtm.js?id=${gtmId}`}
            async
            onLoad={onGtmLoad}
          />
        </>
      )}

      {/* Algolia */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
          !function(e,a,s,i,c){
            e.AlgoliaAnalyticsObject=s
            e[s]=e[s]||function(){
            (e[s].queue=e[s].queue||[]).push(arguments)}
          }(window,document,"aa");
    
          aa('init', {
            appId: '${keys.algoliaAppId}',
            apiKey: '${keys.algoliaAppKey}'
          });`,
        }}
      />
      <script
        src="https://cdn.jsdelivr.net/npm/search-insights@1.6.3"
        async
        defer
      />
    </>
  )
}
