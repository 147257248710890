import {
  type LoaderFunctionArgs,
  type SerializeFrom,
  json,
} from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import { BodyScripts, HeadScripts, KeysScript } from './scripts'
import * as config from '../../server/config'
import { getRequestIp, requestGeoIpCountry } from '../../server/geoip.js'
import 'App.scss'
import { ExperimentServer, trackEvent } from 'server/amplitude.server'
import getUserSessionData from 'server/session/getUserSessionData.server'
import { UserContext } from 'services/UserProvider'
import { useInitClientServices } from './rootUtils.client'
import * as importedMeta from './meta'
import { transformServerKeys } from 'server/transformServerKeys.server'
import { translateManager } from 'services/TranslateManager'
import { getAppLanguage } from 'server/getAppLanguage.server'
import { KeysContext } from 'services/KeysProvider'
import { LangContext } from 'services/LangProvider'
import { ExperimentContext } from 'services/ExperimentManager/ExperimentContext'
import { DeviceContext, type DeviceType } from 'services/DeviceContext'
import DefaultProviders from 'DefaultProviders'
import { getExperimentsUser, useInitExperimentClient } from './rootHelpers'
import { useMemo } from 'react'
import UAParser from 'ua-parser-js'
import { initialUserIdCookie } from 'server/session/cookies.server'
import { createHead } from 'remix-island'
import { useReloadOnNewServiceWorker } from '../hooks/useReloadOnNewServiceWorker'

export const meta = importedMeta.meta
export const links = importedMeta.links

export const Head = createHead(() => (
  <>
    <Meta />
    <Links />
  </>
))

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  const cookiesHeader = request.headers.get('Cookie')
  const userAgent = request.headers.get('user-agent')

  function trackLoad(userId: string) {
    trackEvent('Load Start', userId, {
      ipAddress: getRequestIp(request),
      userAgent,
      path: context.path,
    })
  }

  const device = new UAParser(userAgent).getDevice()
  const deviceType: DeviceType =
    device.type === 'mobile'
      ? 'mobile'
      : device.type === 'tablet'
      ? 'tablet'
      : 'desktop'

  const countryResult = requestGeoIpCountry(request)

  if (config.redirectGermanUsers && countryResult?.country === 'DE') {
    const host = request.headers.get('host')
    if (host && !host.includes('mixtiles.de')) {
      const redirectURL = new URL(request.url)
      redirectURL.host = 'mixtiles.de'
      redirectURL.port = '' // Clear the port if present (localhost)
      return redirect(redirectURL.toString(), 302)
    }
  }
  const keys = await transformServerKeys(request, context)

  const { language, responseHeaders: languageResponseHeaders } =
    await getAppLanguage(request, context.query?.language, keys.ipCountry)

  const { user, responseHeaders } = await getUserSessionData(request)

  const initialUserId = await initialUserIdCookie.parse(cookiesHeader)
  // If initial_user_id cookie is present, use that as the user id
  const experimentsUserId = initialUserId ?? user.username
  const features = await ExperimentServer.fetchV2(
    getExperimentsUser(experimentsUserId, language, keys.ipCountry)
  )

  const headers = new Headers()
  if (languageResponseHeaders) {
    Object.entries(languageResponseHeaders).forEach(([key, value]) => {
      headers.append(key, value)
    })
  }
  if (responseHeaders) {
    Object.entries(responseHeaders).forEach(([key, value]) => {
      headers.append(key, value)
    })
  }

  trackLoad(user.username)

  return json(
    {
      keys,
      user,
      language,
      features,
      deviceType,
      experimentsUserId,
    },
    { headers }
  )
}

function RootLayout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root')

  if (!data) {
    // Error state layout
    return (
      <>
        <div id="root">{children}</div>
        <Scripts />
      </>
    )
  }

  return <MainLayout data={data}>{children}</MainLayout>
}

function MainLayout({
  children,
  data,
}: {
  children: React.ReactNode
  data: SerializeFrom<typeof loader>
}) {
  const { keys, user, language, features, deviceType, experimentsUserId } = data

  const deviceContextValue = useMemo(() => ({ type: deviceType }), [deviceType])

  const experimentClient = useInitExperimentClient({
    features,
    keys,
    userId: experimentsUserId,
    language,
  })

  if (!translateManager.didInit) {
    translateManager.init(language)
  }

  if (useInitClientServices) {
    useInitClientServices(keys, user)
  }

  return (
    <>
      <DeviceContext.Provider value={deviceContextValue}>
        <ExperimentContext.Provider value={experimentClient}>
          <LangContext.Provider value={language}>
            <KeysContext.Provider value={keys}>
              <UserContext.Provider value={user}>
                <DefaultProviders>
                  <div id="root">{children}</div>
                </DefaultProviders>
              </UserContext.Provider>
            </KeysContext.Provider>
          </LangContext.Provider>
        </ExperimentContext.Provider>
      </DeviceContext.Provider>

      <KeysScript keys={keys} />

      <ScrollRestoration />
      <Scripts />

      <HeadScripts keys={keys} />
      <BodyScripts keys={keys} />

      <img
        width="0"
        height="0"
        style={{ display: 'none', visibility: 'hidden' }}
        src="/api/web-app-load"
      />
    </>
  )
}

// @ts-ignore
export const Layout = withSentry(RootLayout)

export default function App() {
  useReloadOnNewServiceWorker()
  return <Outlet />
}

export function ErrorBoundary() {
  const error = useRouteError()
  console.error(error)

  captureRemixErrorBoundaryError(error)

  return <div>Error</div>
}
