import { useNavigate, useLocation } from '@remix-run/react'
import { useEffect } from 'react'
import useOnMount from 'hooks/useOnMount'
import { remixNavigationManager } from 'services/remixNavigationManager'
import { type UserContextValue } from 'services/UserProvider'
import { userState } from 'services/User/userState'
import useCrashLogger from '../hooks/useCrashLogger'
import { analytics } from 'services/Analytics/Analytics'

export const useInitClientServices = (
  keys: Record<string, any>,
  user: UserContextValue
) => {
  useOnMount(() => {
    if (!userState.user) {
      userState.user = user
    }

    analytics.init()
    analytics.identifyUser(user.username)
  })

  useOnMount(() => {
    const rootElement = document.getElementById('root')
    rootElement?.setAttribute('data-hydrated', 'true')
  })

  useCrashLogger()

  useRemixNavigationSync()
}

const useRemixNavigationSync = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    remixNavigationManager.update(navigate, location)
  }, [navigate, location])
}
