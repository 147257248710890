import React from 'react'
import { SnackbarProvider as Provider } from 'notistack'
import { SNACKBAR_VARIANTS } from './Snackbar.consts'
import { makeStyles } from '@mui/styles'
import CommonSnackbar from './CommonSnackbar/CommonSnackbar'

const useStyles = makeStyles({
  containerRoot: {
    zIndex: '1700 !important',
    pointerEvents: 'none !important',
    '& > div': {
      pointerEvents: 'none !important',
    },
  },
})

export default function SnackbarProvider({ children }) {
  const classes = useStyles()
  return (
    <Provider
      TransitionProps={{
        style: {
          transitionTimingFunction: 'cubic-bezier(0.47,1.65,0.68,0.9)',
        },
      }}
      autoHideDuration={6000}
      transitionDuration={300}
      disableWindowBlurListener
      maxSnack={1}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      Components={{
        [SNACKBAR_VARIANTS.COMMON]: CommonSnackbar,
      }}
      classes={{ containerRoot: classes.containerRoot }}
    >
      {children}
    </Provider>
  )
}
