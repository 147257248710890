import { useLocation } from '@remix-run/react'
import { useEffect } from 'react'
import { logger } from '../services/logger'
import {
  getServiceWorkerState,
  SERVICE_WORKER_STATE,
} from '../serviceWorkerInstallation'

export const useReloadOnNewServiceWorker = () => {
  const location = useLocation()
  useEffect(() => {
    if (getServiceWorkerState() === SERVICE_WORKER_STATE.INSTALLED) {
      logger.info('service worker installed - reload', {
        location: location.pathname,
      })

      // make sure the reload happens after window.location updated
      setTimeout(() => window.location.reload(), 150)
    }
  }, [location])
}
