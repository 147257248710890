import { styled, css } from 'styled-components'
import { EnterArrow } from '../../../icons'
import { LANGUAGES } from '../../../locale/Translation.consts'
import { onMediumScreen } from 'mixtiles-web-common'
import { ReactComponent as XIcon } from '../../../icons/assets/X12x12.svg'

interface BackgroundProps {
  hasShadow: boolean
  width: string
}

export const Background = styled.div<BackgroundProps>`
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ hasShadow }) =>
    hasShadow
      ? '0px 4px 23px 0px rgba(43, 5, 20, 0.02), 0px 4px 14px 0px rgba(43, 5, 20, 0.05)'
      : 'none'};
  border: 1px solid ${({ theme }) => theme.colors.grayMedium};
  border-radius: 21px;
  z-index: -1;
  width: ${({ width }) => width};
  transition: width 200ms;
`

interface ContentProps {
  $showIllustration: boolean
}

export const Content = styled.div<ContentProps>`
  margin-top: 1px;
  gap: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 95%;
  text-align: center;
  padding: 13px 24px 11px 24px;
  ${onMediumScreen()} {
    padding: 13px 20px 11px 20px;
  }
  user-select: none;
`

interface IllustrationSizeProp {
  $hasBigIcon: boolean
}

export const IconWrapper = styled.div<IllustrationSizeProp>`
  ${({ $hasBigIcon }) =>
    $hasBigIcon
      ? css`
          width: 36px;
          height: 36px;
        `
      : css`
          width: 12px;
          height: 12px;
        `}

  align-self: center;
`

interface ActionProps {
  $language: string
}

export const Action = styled.div<ActionProps>`
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.primary};
  align-self: center;
  font-size: 16px;
  line-height: 120%;
  text-decoration-line: underline;
  cursor: pointer;
  font-weight: 600;
  ${({ $language }) =>
    $language === LANGUAGES.GERMAN &&
    css`
      font-size: 14px;
    `}
  &:hover {
    color: ${({ theme }) => theme.colors.primaryAccent};
  }
`

export const TextWrapper = styled.div<IllustrationSizeProp>`
  display: flex;
  gap: ${({ $hasBigIcon }) => ($hasBigIcon ? '20px' : '28px')};
`

export const LeftWrapper = styled.div<IllustrationSizeProp>`
  display: flex;
  gap: ${({ $hasBigIcon }) => ($hasBigIcon ? '20px' : '8px')};
`

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

interface TitleProps {
  $showAction: boolean
  $shouldMinimize: boolean
  $language: string
  $hasBigIcon: boolean
}

export const Title = styled.span<TitleProps>`
  color: ${({ theme }) => theme.colors.black};
  margin-left: 1px;
  text-align: left;
  font-size: 16px;

  ${({ $hasBigIcon }) =>
    $hasBigIcon
      ? css`
          font-weight: 600;
          line-height: 170%;
        `
      : css`
          font-weight: 500;
          line-height: 125%;
        `}

  ${({ $shouldMinimize, $language }) => {
    if ($language === LANGUAGES.GERMAN)
      return css`
        font-size: 14px;
      `
    if ($shouldMinimize)
      return css`
        font-size: 15.5px;
      `
  }}
`

export const SubtitleWrapper = styled.div`
  display: flex;
  gap: 5px;
`

export const Subtitle = styled.span<IllustrationSizeProp>`
  color: ${({ theme }) => theme.colors.grayDark};
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.05px;
  text-align: left;

  white-space: pre-line;
  line-height: ${({ $hasBigIcon }) => ($hasBigIcon ? '120%' : '125%')};
`

export const XIconWrapper = styled.div<IllustrationSizeProp>`
  box-shadow: 0 0 0 6px ${({ theme }) => theme.colors.white};
  display: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayMedium};
    box-shadow: 0 0 0 6px ${({ theme }) => theme.colors.grayMedium};
    border-radius: 7px;
    transition:
      background-color 0.2s,
      box-shadow 0.2s,
      border-raidus 0.2s;
  }

  ${onMediumScreen()} {
    display: ${({ $hasBigIcon }) => ($hasBigIcon ? 'none' : 'block')};
  }
`

export const ThinXIcon = styled(XIcon)<IllustrationSizeProp>`
  width: 12px;
  height: 12px;
  cursor: pointer;
  border-radius: 0;
  display: none;
  ${onMediumScreen()} {
    display: ${({ $hasBigIcon }) => ($hasBigIcon ? 'none' : 'block')};
  }
`

export const Arrow = styled(EnterArrow)`
  width: 12px;
  height: 12px;
  margin-top: 3.375px;
`
